.makeInIndia {
  padding-bottom: 2rem;
  margin-bottom: 4%;
}

.makeInIndia__image img {
  width: 65%;
}

.makeInIndia__text {
  width: 40%;
}

.makeInIndia__text h6 {
  font-size: 1.2rem;
}

@media screen and (max-width: 880px) {
  .makeInIndia__text {
    width: 60%;
  }
  .makeInIndia__image img {
    width: 55%;
    margin-left: 4rem;
    margin-bottom: 2.5rem;
  }
}
