.colors {
  padding-bottom: 6%;
}

.colors__main {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.colors__circle {
  height: 110px;
  width: 110px;
  border: 1px solid black;
  border-radius: 50%;
}

.colors h2 {
  font-weight: 700;
  color: #005792;
}

.colors__text {
  font-weight: 600;
  margin-top: 0.5rem;
  text-align: center;
}

.colors__button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.colors__circle:hover {
  transition: ease-in-out 0.18s;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}

.colors a {
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 810px) {
  .colors__circle {
    height: 85px;
    width: 85px;
    margin-right: 1.2rem;
  }
  .colors__maple,
  .colors__evita,
  .colors__black {
    margin-left: -1rem;
  }
}
