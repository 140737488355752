.home__features {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-around;
  align-items: center;
  row-gap: 3rem;
  margin-top: 5%;
  padding-bottom: 10%;
}

.features__heading {
  color: #005792;
  font-weight: 700;
}

.home__feature:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

@media screen and (max-width: 990px) {
  .home__features {
    grid-template-columns: auto auto;
  }
}

@media screen and (max-width: 500px) {
  .home__features {
    grid-template-columns: auto;
  }
}

@media screen and (min-width: 1500px) {
  .home__features {
    grid-template-columns: auto auto auto auto;
    justify-content: space-evenly;
  }
}
