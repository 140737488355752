.products {
  margin-top: 4rem;
}

.products__heading {
  font-weight: 700;
  color: #005792;
  margin-bottom: 1rem;
}

.products__text {
  padding: 1.5rem 0.5rem;
  margin: 2rem 2rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.products__doors {
  margin-top: 8%;
}

.products__cupboards {
  margin-top: 8%;
  margin-bottom: 5%;
}

.products__wallpanelling {
  margin-top: 8%;
}

.products__description {
  line-height: 1.8rem;
}

.products__button > a {
  color: inherit;
  text-decoration: none;
}

.product__text {
  width: 55%;
}

@media screen and (max-width: 890px) {
  .products__slider {
    margin-top: 3rem;
  }
  .product__text {
    width: 80%;
  }
  .products__text {
    margin: 1rem 1rem;
  }
}
