.faqs {
  margin-bottom: 4%;
}

.faqs__item {
  padding: 1.5rem 3rem;
}

.faqs h3 {
  font-weight: 700;
  color: #005792;
}
