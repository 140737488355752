.counter__title {
  font-weight: 700;
  color: #005792;
}

.counter__count {
  margin-top: 4%;
}

.counter {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-around;
  align-items: center;
  background-color: #ffffff;
  padding: 2rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.counter__count h1 {
  font-weight: 600;
  color: #ff725e;
}

.customer-image {
  width: 400px;
}

@media screen and (max-width: 800px) {
  .counter {
    grid-template-columns: auto;
  }
  .customer-image {
    width: 300px;
  }
}

@media screen and (min-width: 1500px) {
  .counter {
    justify-content: space-evenly;
  }
}
