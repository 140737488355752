.doors {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.doors img {
  width: 350px;
  margin-bottom: 2rem;
  object-fit: contain;
}

@media screen and (max-width: 890px) {
  .doors {
    height: 550px;
  }
  .doors h3 {
    font-size: 1.4rem;
    margin-left: 4rem;
  }
  .doors img {
    width: 300px;
  }
}
