.footer {
  background-color: #003d66;
  height: 100%;
  padding: 2rem 1rem;
  align-items: center;
  justify-content: space-around;
  display: grid;
  grid-template-columns: auto auto auto;
}

.footer__heading {
  font-weight: 700;
  color: #f6f6f6;
}

.footer__products,
.footer__useful {
  display: flex;
  flex-direction: column;
}

.footer a {
  color: #e6e6e6;
  text-decoration: none;
}

.footer__heading,
.useful__heading {
  color: #f6f6f6;
  font-weight: 600;
  font-size: 1.1rem;
}

.footer__icons {
  display: flex;
  align-items: center;
}

.footer__icons p {
  color: white;
  margin-top: 1rem;
}

.footer__icons > * {
  margin-left: 1rem;
}

.useful__heading {
  margin-bottom: 1rem;
}

.footer a:hover {
  text-decoration: underline;
}

.footer__input::placeholder {
  font-size: 0.8rem;
}

@media screen and (max-width: 652px) {
  .footer {
    grid-template-columns: auto auto;
  }
  .footer__main {
    grid-column: 1 / 3;
  }
  .footer__heading {
    font-size: 1rem;
  }
  .footer__icons {
    margin-left: -1rem;
    margin-bottom: 1.5rem;
  }
  .footer__products {
    margin-left: 2rem;
  }
  .mainHeading {
    margin-left: 2rem;
  }
  .footer__icons {
    margin-left: 0.5rem;
  }
  .footer__button{
   margin-top: 2rem;
   margin-left: 0.5rem;
  }
}