.clients {
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  margin-bottom: 5%;
}

.clients__text h3 {
  font-size: 24px;
  font-weight: 700;
  color: #005792;
  padding: 1rem;
}

.clients__images {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.clients__images img {
  object-fit: contain;
  cursor: pointer;
  padding: 1rem;
}

.tata__image {
  width: 115px;
  height: 115px;
}

.clients__ts {
  width: 110px;
  height: 110px;
}

.clients__railway {
  width: 120px;
  height: 120px;
}

.clients__ap {
  width: 120px;
  height: 120px;
}

.clitens__itc {
  width: 120px;
  height: 120px;
}

.clitens__yashoda {
  width: 125px;
  height: 125px;
}

@media screen and (max-width: 900px) {
  .clients__text h3 {
    font-size: 17px;
  }
  .clients__images img {
    margin-left: 1.5rem;
  }
  .clients__images {
    margin-left: 2rem;
  }
  .clients__ts {
    margin-left: 2rem !important;
  }
}
