.home__main {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 4%;
}

.main__image {
  width: 36%;
  max-width: 550px;
  min-width: 350px;
  margin-top: 2rem;
}

.main__image img {
  object-fit: contain;
}

.main__heading h1 {
  font-weight: 700;
  color: #005792;
}

.main__heading {
  display: flex;
  flex-direction: column;
  height: 300px;
  justify-content: center;
  align-items: center;
  margin-left: 3rem;
}

.main__heading h5 {
  line-height: 1.8rem;
}

.main__heading span {
  color: #fe4d53;
  font-weight: 700;
}

.section__image {
  width: 25%;
}

.home__main > a {
  text-decoration: none;
}

@media screen and (max-width: 990px) {
  .home__main {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 2rem;
    margin-left: -3rem;
  }
  .main__image {
    margin-left: 4rem;
  }
  .main__heading h1 {
    font-size: 1.3rem;
  }
  .main__heading h5 {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1900px) {
  .home__main {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}
