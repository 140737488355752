.contacts {
  margin-top: 3rem;
  padding-bottom: 5%;
}

.dealership {
  width: 380px;
}

.contacts__heading {
  font-weight: 700;
  color: #005792;
}

.contacts__inputs {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 50px;
}

.grid-full {
  grid-column: 1 / 3;
}

.contact__image {
  width: 380px;
}

.contacts__info {
  display: flex;
}

.contacts__info h5 {
  font-size: 1rem;
  font-weight: 500;
}

.ml {
  margin-left: 8.5rem;
}

@media screen and (max-width: 535px) {
  .contacts__inputs {
    margin-left: 1rem !important;
  }
  .dealership {
    width: 220px;
  }
  .contact__text {
    margin-left: 3rem;
  }
  .contacts__button {
    margin-left: 7rem !important;
  }
  .contact__image {
    width: 220px;
  }
  .aboutTexts {
    width: 90% !important;
  }
  .map {
    margin-left: 0.5rem !important;
  }
}
