@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400;600;700;800&display=swap);
body {
  margin: 0;
  font-family: "Poppins", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
}

.App {
  font-family: "Poppins";
}

.navbar {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 99;
  background-color: white !important;
}

.navbar__home,
.navbar__about,
.navbar__products,
.navbar__contact {
  font-weight: 700;
  font-size: 1.01rem;
  color: #333333 !important;
}

.navbar__logo {
  margin-bottom: 0.15rem;
}

.navbar a {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 460px) {
  .navbar__logo {
    width: 110px;
    height: 25px;
  }
  .nav-link {
    margin-left: 1.5rem;
  }
  .dropdown-menu {
    margin-left: 1.5rem;
  }
}

.home__main {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 4%;
}

.main__image {
  width: 36%;
  max-width: 550px;
  min-width: 350px;
  margin-top: 2rem;
}

.main__image img {
  object-fit: contain;
}

.main__heading h1 {
  font-weight: 700;
  color: #005792;
}

.main__heading {
  display: flex;
  flex-direction: column;
  height: 300px;
  justify-content: center;
  align-items: center;
  margin-left: 3rem;
}

.main__heading h5 {
  line-height: 1.8rem;
}

.main__heading span {
  color: #fe4d53;
  font-weight: 700;
}

.section__image {
  width: 25%;
}

.home__main > a {
  text-decoration: none;
}

@media screen and (max-width: 990px) {
  .home__main {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 2rem;
    margin-left: -3rem;
  }
  .main__image {
    margin-left: 4rem;
  }
  .main__heading h1 {
    font-size: 1.3rem;
  }
  .main__heading h5 {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1900px) {
  .home__main {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
}

.contacts {
  margin-top: 3rem;
  padding-bottom: 5%;
}

.dealership {
  width: 380px;
}

.contacts__heading {
  font-weight: 700;
  color: #005792;
}

.contacts__inputs {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 50px;
  column-gap: 50px;
}

.grid-full {
  grid-column: 1 / 3;
}

.contact__image {
  width: 380px;
}

.contacts__info {
  display: flex;
}

.contacts__info h5 {
  font-size: 1rem;
  font-weight: 500;
}

.ml {
  margin-left: 8.5rem;
}

@media screen and (max-width: 535px) {
  .contacts__inputs {
    margin-left: 1rem !important;
  }
  .dealership {
    width: 220px;
  }
  .contact__text {
    margin-left: 3rem;
  }
  .contacts__button {
    margin-left: 7rem !important;
  }
  .contact__image {
    width: 220px;
  }
  .aboutTexts {
    width: 90% !important;
  }
  .map {
    margin-left: 0.5rem !important;
  }
}

.about {
  margin-top: 1.5rem;
  padding-bottom: 5%;
}

.about__image {
  width: 420px;
}

.about__heading {
  font-weight: 700;
  color: #005792;
  margin-bottom: 1rem;
}

.about h6 {
  font-size: 0.98rem;
  line-height: 1.8rem;
}

.mission__image {
  width: 370px;
}

.about__text {
  width: 45%;
}

@media screen and (max-width: 535px) {
  .about__image {
    width: 300px;
  }
  .mission__image {
    width: 300px;
    margin-left: -2rem;
  }
  .about__text {
    width: 68%;
  }
}

.home__features {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-around;
  align-items: center;
  grid-row-gap: 3rem;
  row-gap: 3rem;
  margin-top: 5%;
  padding-bottom: 10%;
}

.features__heading {
  color: #005792;
  font-weight: 700;
}

.home__feature:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

@media screen and (max-width: 990px) {
  .home__features {
    grid-template-columns: auto auto;
  }
}

@media screen and (max-width: 500px) {
  .home__features {
    grid-template-columns: auto;
  }
}

@media screen and (min-width: 1500px) {
  .home__features {
    grid-template-columns: auto auto auto auto;
    justify-content: space-evenly;
  }
}

.counter__title {
  font-weight: 700;
  color: #005792;
}

.counter__count {
  margin-top: 4%;
}

.counter {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-around;
  align-items: center;
  background-color: #ffffff;
  padding: 2rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.counter__count h1 {
  font-weight: 600;
  color: #ff725e;
}

.customer-image {
  width: 400px;
}

@media screen and (max-width: 800px) {
  .counter {
    grid-template-columns: auto;
  }
  .customer-image {
    width: 300px;
  }
}

@media screen and (min-width: 1500px) {
  .counter {
    justify-content: space-evenly;
  }
}

.products {
  margin-top: 4rem;
}

.products__heading {
  font-weight: 700;
  color: #005792;
  margin-bottom: 1rem;
}

.products__text {
  padding: 1.5rem 0.5rem;
  margin: 2rem 2rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.products__doors {
  margin-top: 8%;
}

.products__cupboards {
  margin-top: 8%;
  margin-bottom: 5%;
}

.products__wallpanelling {
  margin-top: 8%;
}

.products__description {
  line-height: 1.8rem;
}

.products__button > a {
  color: inherit;
  text-decoration: none;
}

.product__text {
  width: 55%;
}

@media screen and (max-width: 890px) {
  .products__slider {
    margin-top: 3rem;
  }
  .product__text {
    width: 80%;
  }
  .products__text {
    margin: 1rem 1rem;
  }
}

.panels {
  margin-top: 3%;
  margin-left: 3rem;
}

.panel__subheading {
  color: #005792;
  font-weight: 700;
}

.panels__image img {
  width: 350px;
  height: 400px;
}

.panel__designs img {
  width: 58%;
  border: 2px solid black;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 1rem;
}

.panel__designs img:hover {
  filter: brightness(85%);
}

.panel__designs * {
  margin-right: 1rem;
}

.lightPanelDesigns img {
  width: 42%;
}

.specifications__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.specifications__image {
  width: 300px;
  margin-bottom: 2rem;
  object-fit: contain;
}

.panel__text {
  width: 56.2%;
}

@media screen and (max-width: 875px) {
  .panel__text {
    width: 100%;
    margin-left: -1rem;
  }
  .panel__designs {
    display: flex;
    flex-wrap: wrap;
  }
  .panel__designs img {
    width: 30%;
  }
  .panels {
    margin-left: 0rem;
  }
  .panel__button {
    width: 42% !important;
  }
  .panel__selector {
    margin-left: -2rem !important;
  }
}

.process {
  padding-top: 4%;
  padding-bottom: 4%;
}

.process__card {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.process h2 {
  font-weight: 700;
  color: #005792;
}

/* @media screen and (max-width: 880px) {
  .process__card {
    width: 300px;
  }
} */

.colors {
  padding-bottom: 6%;
}

.colors__main {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.colors__circle {
  height: 110px;
  width: 110px;
  border: 1px solid black;
  border-radius: 50%;
}

.colors h2 {
  font-weight: 700;
  color: #005792;
}

.colors__text {
  font-weight: 600;
  margin-top: 0.5rem;
  text-align: center;
}

.colors__button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}

.colors__circle:hover {
  transition: ease-in-out 0.18s;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}

.colors a {
  text-decoration: none;
  color: white;
}

@media screen and (max-width: 810px) {
  .colors__circle {
    height: 85px;
    width: 85px;
    margin-right: 1.2rem;
  }
  .colors__maple,
  .colors__evita,
  .colors__black {
    margin-left: -1rem;
  }
}

.clients {
  background: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  margin-bottom: 5%;
}

.clients__text h3 {
  font-size: 24px;
  font-weight: 700;
  color: #005792;
  padding: 1rem;
}

.clients__images {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.clients__images img {
  object-fit: contain;
  cursor: pointer;
  padding: 1rem;
}

.tata__image {
  width: 115px;
  height: 115px;
}

.clients__ts {
  width: 110px;
  height: 110px;
}

.clients__railway {
  width: 120px;
  height: 120px;
}

.clients__ap {
  width: 120px;
  height: 120px;
}

.clitens__itc {
  width: 120px;
  height: 120px;
}

.clitens__yashoda {
  width: 125px;
  height: 125px;
}

@media screen and (max-width: 900px) {
  .clients__text h3 {
    font-size: 17px;
  }
  .clients__images img {
    margin-left: 1.5rem;
  }
  .clients__images {
    margin-left: 2rem;
  }
  .clients__ts {
    margin-left: 2rem !important;
  }
}

.makeInIndia {
  padding-bottom: 2rem;
  margin-bottom: 4%;
}

.makeInIndia__image img {
  width: 65%;
}

.makeInIndia__text {
  width: 40%;
}

.makeInIndia__text h6 {
  font-size: 1.2rem;
}

@media screen and (max-width: 880px) {
  .makeInIndia__text {
    width: 60%;
  }
  .makeInIndia__image img {
    width: 55%;
    margin-left: 4rem;
    margin-bottom: 2.5rem;
  }
}

.faqs {
  margin-bottom: 4%;
}

.faqs__item {
  padding: 1.5rem 3rem;
}

.faqs h3 {
  font-weight: 700;
  color: #005792;
}

.footer {
  background-color: #003d66;
  height: 100%;
  padding: 2rem 1rem;
  align-items: center;
  justify-content: space-around;
  display: grid;
  grid-template-columns: auto auto auto;
}

.footer__heading {
  font-weight: 700;
  color: #f6f6f6;
}

.footer__products,
.footer__useful {
  display: flex;
  flex-direction: column;
}

.footer a {
  color: #e6e6e6;
  text-decoration: none;
}

.footer__heading,
.useful__heading {
  color: #f6f6f6;
  font-weight: 600;
  font-size: 1.1rem;
}

.footer__icons {
  display: flex;
  align-items: center;
}

.footer__icons p {
  color: white;
  margin-top: 1rem;
}

.footer__icons > * {
  margin-left: 1rem;
}

.useful__heading {
  margin-bottom: 1rem;
}

.footer a:hover {
  text-decoration: underline;
}

.footer__input::placeholder {
  font-size: 0.8rem;
}

@media screen and (max-width: 652px) {
  .footer {
    grid-template-columns: auto auto;
  }
  .footer__main {
    grid-column: 1 / 3;
  }
  .footer__heading {
    font-size: 1rem;
  }
  .footer__icons {
    margin-left: -1rem;
    margin-bottom: 1.5rem;
  }
  .footer__products {
    margin-left: 2rem;
  }
  .mainHeading {
    margin-left: 2rem;
  }
  .footer__icons {
    margin-left: 0.5rem;
  }
  .footer__button{
   margin-top: 2rem;
   margin-left: 0.5rem;
  }
}
.interiors {
  margin-top: 6%;
  padding-bottom: 2rem;
}

.interior__image img {
  width: 600px;
  object-fit: contain;
  border: 1px solid white;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.interior__text h3 {
  font-weight: 700;
  color: #005792;
}

.interior__text h5 {
  font-weight: 600;
}

.interior__text h6 {
  font-size: 1rem;
  font-weight: 400;
  color: gray;
}

.interior__text {
  width: 40%;
  display: flex;
  flex-direction: column;
  height: 300px;
  justify-content: space-around;
}

@media screen and (max-width: 890px) {
  .interior__image img {
    width: 300px;
  }
  .interior__text button {
    width: 55%;
  }
  .interior__text {
    width: 70%;
    margin-top: 2rem;
  }
  .interiors {
    margin-top: 3rem;
  }
}

.interior__text > button > a {
  text-decoration: none;
  color: white;
}

.doors {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.doors img {
  width: 350px;
  margin-bottom: 2rem;
  object-fit: contain;
}

@media screen and (max-width: 890px) {
  .doors {
    height: 550px;
  }
  .doors h3 {
    font-size: 1.4rem;
    margin-left: 4rem;
  }
  .doors img {
    width: 300px;
  }
}

