.process {
  padding-top: 4%;
  padding-bottom: 4%;
}

.process__card {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.process h2 {
  font-weight: 700;
  color: #005792;
}

/* @media screen and (max-width: 880px) {
  .process__card {
    width: 300px;
  }
} */
