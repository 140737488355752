.about {
  margin-top: 1.5rem;
  padding-bottom: 5%;
}

.about__image {
  width: 420px;
}

.about__heading {
  font-weight: 700;
  color: #005792;
  margin-bottom: 1rem;
}

.about h6 {
  font-size: 0.98rem;
  line-height: 1.8rem;
}

.mission__image {
  width: 370px;
}

.about__text {
  width: 45%;
}

@media screen and (max-width: 535px) {
  .about__image {
    width: 300px;
  }
  .mission__image {
    width: 300px;
    margin-left: -2rem;
  }
  .about__text {
    width: 68%;
  }
}
