.navbar {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 99;
  background-color: white !important;
}

.navbar__home,
.navbar__about,
.navbar__products,
.navbar__contact {
  font-weight: 700;
  font-size: 1.01rem;
  color: #333333 !important;
}

.navbar__logo {
  margin-bottom: 0.15rem;
}

.navbar a {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 460px) {
  .navbar__logo {
    width: 110px;
    height: 25px;
  }
  .nav-link {
    margin-left: 1.5rem;
  }
  .dropdown-menu {
    margin-left: 1.5rem;
  }
}
