.interiors {
  margin-top: 6%;
  padding-bottom: 2rem;
}

.interior__image img {
  width: 600px;
  object-fit: contain;
  border: 1px solid white;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.interior__text h3 {
  font-weight: 700;
  color: #005792;
}

.interior__text h5 {
  font-weight: 600;
}

.interior__text h6 {
  font-size: 1rem;
  font-weight: 400;
  color: gray;
}

.interior__text {
  width: 40%;
  display: flex;
  flex-direction: column;
  height: 300px;
  justify-content: space-around;
}

@media screen and (max-width: 890px) {
  .interior__image img {
    width: 300px;
  }
  .interior__text button {
    width: 55%;
  }
  .interior__text {
    width: 70%;
    margin-top: 2rem;
  }
  .interiors {
    margin-top: 3rem;
  }
}

.interior__text > button > a {
  text-decoration: none;
  color: white;
}
