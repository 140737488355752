.panels {
  margin-top: 3%;
  margin-left: 3rem;
}

.panel__subheading {
  color: #005792;
  font-weight: 700;
}

.panels__image img {
  width: 350px;
  height: 400px;
}

.panel__designs img {
  width: 58%;
  border: 2px solid black;
  border-radius: 50%;
  cursor: pointer;
  margin-bottom: 1rem;
}

.panel__designs img:hover {
  filter: brightness(85%);
}

.panel__designs * {
  margin-right: 1rem;
}

.lightPanelDesigns img {
  width: 42%;
}

.specifications__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.specifications__image {
  width: 300px;
  margin-bottom: 2rem;
  object-fit: contain;
}

.panel__text {
  width: 56.2%;
}

@media screen and (max-width: 875px) {
  .panel__text {
    width: 100%;
    margin-left: -1rem;
  }
  .panel__designs {
    display: flex;
    flex-wrap: wrap;
  }
  .panel__designs img {
    width: 30%;
  }
  .panels {
    margin-left: 0rem;
  }
  .panel__button {
    width: 42% !important;
  }
  .panel__selector {
    margin-left: -2rem !important;
  }
}
